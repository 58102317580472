<template>
  <div name="planning_edit" >
        <innertopbar @close="closeWindow()">
      <template v-slot:breadcrumb>
        <router-link to="planning"> Planning </router-link>
        <i class="carret fas fa-caret-right"></i> {{yearName}}
      </template>

    </innertopbar>

     <div class="w-full mx-auto">
    <div class="flex flex-col md:flex-row">
      <div class="md:h-screen md:w-80 mt-6 ml-6 mr-10 pt-6 bg-white rounded-lg mb-10">

       <h4 class="ml-3 p-4 text- font-bold">Year Groups</h4>
          <ul class="list-none">
            <li  v-for="item in yearDropdown" :key="item.id" v-bind:class="yearId == item.id ? 'bg-red-100' : 'bg-list-grey'"
            class="group p-4 ml-3 mb-2 w-10/12 rounded-md font-medium  hover:bg-red-100 cursor-pointer text-xs font-semibold"
            @click="yearId = item.id">
                         <span class="rounded p-1 px-3  mr-4 text-white font-bold group-hover:bg-pink-red text-xs "
                          v-bind:class="yearId == item.id ? 'bg-pink-red' : 'bg-button-black'">{{ item.letter }}</span> {{ item.name }}
                      </li>  

          </ul>
      </div>
      <div i class="md:w-full h-screen overflow-y-scroll bg-white">
        <div class="mt-4 w-full">



            <div v-if="errored" class="empty">
              <p>
                We are not able to retrieve this information at the moment,
                please try back later
              </p>
            </div>

            <div id="content-area" v-else>
              <div style="width:100%; text-align:center;" v-if="loading">
                <i class="spinner fa fa-spinner fa-spin"></i>
              </div>

                   <savenotice :title="'Planning'" ref="saveNoticeTop" ></savenotice>
                  
                  <div  v-if="myUnitsLoaded" class="mb-8">
                    <h2 class="text-lg m-2 font-bold text-gray-700 mx-6 capitalize">My Units</h2>
                    <div v-for="unit in myUnits" :key="unit.id" 
                      class="mt-1 pt-2  pb-1 mx-6 flex flex-row even:bg-table-alt-row ">
                        <div class="w-3/12 mr-4 pl-2">  
                         <h3 class="text-sm font-bold">{{unit.name}}</h3>
                          <div v-for="cat in cats" :key="cat.id" v-if="cat.id == unit.category_id" class="text-xs text-blue-300 italic">
                                       {{ cat.name }}
                                    </div>
                       </div>
                        <div class="grid-cols-6"  v-for="(term, index) in terms" :key="index">
                        <button type="button" @click="setPlanning(unit, term)"
                          class="border-none col-span-1 p-2 mt-1 rounded mr-2 text-xs font-semibold 
                        hover:bg-green-300 hover:text-white icon text" v-bind:class="setStyle(unit, term)" > 
                            {{ term.name }}
                          </button>

                      </div>      

                      </div>
                    </div>

                  <div  v-if="commUnitsLoaded" class="mb-8 border-t-8 border-gray-100">
                   <h2 class="text-lg m-2 font-bold text-gray-700 mx-6 pt-4 capitalize">Community Units</h2>
                    <div v-for="unit in commUnits" 
                      class="mt-1 pt-2  pb-1 flex flex-row even:bg-table-alt-row mx-6 ">
                        <div class="w-3/12 mr-4 pl-2">  
                         <h3 class="text-sm font-bold">{{unit.name}}</h3>
                          <div v-for="cat in cats" v-if="cat.id == unit.category_id" class="text-xs text-blue-300 italic">
                                       {{ cat.name }}
                                    </div>
                       </div>
                        <div class="grid-cols-6"  v-for="term in terms">
                        <button type="button" @click="setPlanning(unit, term)"
                          class="border-none col-span-1 p-2 mt-1 rounded mr-2 text-xs font-semibold 
                        hover:bg-green-300 hover:text-white icon text" v-bind:class="setStyle(unit, term)" > 
                            {{ term.name }}
                          </button>

                      </div>      

                      </div>
                    </div>


                    <div v-if="moduleUnitsLoaded" class="mb-8 border-t-8 border-gray-100">
                       <h2 class="text-lg m-2 font-bold text-gray-700 mx-6 pt-4 capitalize">Pippa and Eddie</h2>
                      <div v-for="unit in moduleUnits" 
                      class="mt-1 pt-2  pb-1 flex flex-row even:bg-table-alt-row mx-6 ">
                        <div class="w-3/12 mr-4 pl-2">  
                         <h3 class="text-sm font-bold">{{unit.name}}</h3>
                          <div v-for="cat in cats" v-if="cat.id == unit.category_id" class="text-xs text-blue-300 italic">
                                       {{ cat.name }}
                                    </div>
                       </div>
                        <div class="grid-cols-6"  v-for="term in terms">
                        <button type="button" 
                         @click="setPlanningWithCheck(unit, term)"
                          class="border-none col-span-1 p-2 mt-1 rounded mr-2 text-xs font-semibold hover:text-white icon text" 
                          v-bind:class="setModuleStyle(unit, term)" > 
                            {{ term.name }}
                          </button>

                      </div>      
                    </div>
                  </div>

                    <div v-if="componentLoaded" class="mb-8 border-t-8 border-gray-100">
                       <h2 class="text-lg m-2 font-bold text-gray-700 mx-6 pt-4 capitalize">Core Units</h2>
                      <div v-for="unit in orderedUnits" 
                      class="mt-1 pt-2  pb-1 flex flex-row even:bg-table-alt-row mx-6 ">
                        <div class="w-3/12 mr-4 pl-2">  
                         <h3 class="text-sm font-bold">{{unit.name}}</h3>
                          <div v-for="cat in cats" v-if="cat.id == unit.category_id" class="text-xs text-blue-300 italic">
                                       {{ cat.name }}
                                    </div>
                       </div>
                        <div class="grid-cols-6"  v-for="term in terms">
                        <button type="button" @click="setPlanning(unit, term)"
                          class="border-none col-span-1 p-2 mt-1 rounded mr-2 text-xs font-semibold 
                        hover:bg-green-300 hover:text-white icon text" v-bind:class="setStyle(unit, term)" > 
                            {{ term.name }}
                          </button>

                      </div>      
                    </div>
                  </div>

                     


              <savenotice :title="'Planning'" ref="saveNoticeBottom" class="right"></savenotice>
              </div>

  
    </div>
  </div>

    </div>
     </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  termList,
  calendarYearList,
  colours,
} from "../assets/js/utils";
import { SchoolService, BaseService } from "@/services/school.service";
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";

const innertopbar = () => import("@/components/InnerTopBar.vue");
const savenotice = () => import('@/components/SaveNotice.vue')
const dropdown = () => import('@/components/Dropdown.vue')

export default {
  name: 'planning_edit',
  mixins: [yearGroupMixin],
   props: {
    },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
      settings: "settings"
    }),
   myUnitsLoaded() {
    return this.myUnits.length > 0
   },
    commUnitsLoaded() {
    return this.commUnits.length > 0
   },
   moduleUnitsLoaded() {
    return this.moduleUnits.length > 0
   }, 
    componentLoaded () {
      return this.orderedUnits.length > 0
    },
    yearDropdown() {
      return this.years;
    },
    yearName() {
      let vm = this
      if (vm.years.length > 0) {
        let year = vm.years
          .filter(year => {
            return vm.yearId === year.id;
          })
          .shift();

          return year.name
      }
    },
    terms() {
        return termList()
      },
    myUnits() {
      var vm = this;
      let year = vm.yearId
      var vm = this;
      let type = vm.courseTypes.find(c => c.key_value === 'school')

        var filtered = vm.schoolUnits.filter(function(unit) {
          let filtered = true;

          filtered = unit.year_id == year && unit.type_id === type.id

          return filtered;
        });

        return this.$orderBy(filtered, ["name", "category_id"]);
    },
    commUnits() {
      var vm = this;
      let year = vm.yearId
      var vm = this;
      let type = vm.courseTypes.find(c => c.key_value === 'community')

        var filtered = vm.schoolUnits.filter(function(unit) {
          let filtered = true;

          filtered = unit.year_id == year && unit.type_id === type.id

          return filtered;
        });

        return this.$orderBy(filtered, ["name", "category_id"]);
    },
    orderedUnits() {
      var vm = this;
      let year = vm.yearId;
      let types = vm.courseTypes
        .filter(c => c.key_value === 'free' || c.key_value === 'organisation')
        .map(obj => obj.id)

      var filtered = vm.units.concat(vm.schoolUnits).filter(function(unit) {
          let filtered = true;

          filtered = unit.year_id == year && types.includes(unit.type_id)

          return filtered;
        });

        return this.$orderBy(filtered, ["name", "category_id"]);
    },
    moduleUnits() {
      var vm = this;
      let year = vm.yearId;
        let type = vm.courseTypes.find(c => c.key_value === 'pippaeddie')
        var filtered = vm.units.filter(function(unit) {
          let filtered = true;

          filtered = unit.year_id == year && unit.type_id === type.id

          return filtered;
        });

        return this.$orderBy(filtered, ["name", "category_id"]);
    }
  },
  data () {
    return {
      school: this.$store.getters.school,
      errored: false,
      units: [],
      schoolUnits: [],
      cats: [],
      courseTypes: [],
      yearId: 1,
      planning: [],
      loading: true,
    }
  },
  created() { 

     SchoolService.getAll("planning")
      .then(response => this.planning = response.data)
      .catch(err => {
          console.log("Planning " + err);
          this.errored = true;
      })
      .finally(() => (this.loading = false));

       BaseService.getAll("categories")
      .then(response => this.buildCategoryList(response.data))
      .catch(res => {
        console.log(res);
      });
      BaseService.getAll("course-types")
      .then(response => this.courseTypes = response.data)
      .catch(res => {
        console.log(res);
      });

      this.fetchBaseData();
      this.fetchSchoolData()
      

  },
  mounted () {
  },
  methods: {
    fetchBaseData() {
      let freeParam = this.settings.display.courses == 'custom' ? '' : 'free'

      BaseService.getAll("courses?types=pippaeddie," + freeParam)
        .then(response => this.buildUnitList(response.data))
        .catch(err => {
          console.log("Courses " + err);
          this.errored = true;
        });
    },
    fetchSchoolData() {
      SchoolService.getAll("courses")
        .then(response => this.buildSchoolUnitList(response.data))
        .catch(err => {
          console.log("Courses " + err);
          this.errored = true;
        });
    },
    buildUnitList(data) {
        this.units = data;
      },
    buildSchoolUnitList(data) {
      this.schoolUnits = data
    },
    buildCategoryList(data) {
      this.cats = data;
    },
    findValue(unit, term) {
      let vm = this
      return vm.planning.find(p => p.term === term.key && p.course.year_id === vm.yearId && p.course_id == unit.id)
    },
    setStyle(unit, term) {
      let result = this.findValue(unit, term)
        if (result == undefined) {
           return 'bg-gray-200 text-gray-500 far fa-dot-circle'
        } else {
           return 'bg-green-300 text-white fas fa-check-circle'
        }
    },
    isAllowed(unit) {
      let vm = this
      let type = vm.courseTypes.find(c => c.id === unit.type_id)
      return type != undefined && vm.currentAccess.hasModule(type.key_value)
    },
    setModuleStyle(unit, term) {
      if (this.isAllowed(unit)) {
        return this.setStyle(unit, term)
      } else {
        return 'bg-orange-400 text-gray-200 fas fa-lock hover:bg-orange-600 cursor-not-allowed'
      }
    },
    setPlanningWithCheck(unit, term) {
      if (this.isAllowed(unit)) {
        this.setPlanning(unit, term)
      }
    },
    setPlanning (unit, term) {
      let vm = this
      var result = vm.findValue(unit, term)

      if (result != undefined) {
        SchoolService.delete("planning", result.identifier, null)
          .then(response => {
            vm.planning.splice(vm.planning.findIndex(function(i){
                return i.identifier === result.identifier;
            }), 1);
          })
          .catch(err => {
            this.completion(err);
          });
      } else {

        let inUseResult = vm.planning.find(p => p.course.year_id === vm.yearId && p.course_id == unit.id)
        
         if (inUseResult != undefined) {
            inUseResult.term = term.key           
            SchoolService.update("planning", inUseResult.identifier, inUseResult)
              .catch(err => {
                this.completion(err);
              });
        } else {
            let calendarYear = calendarYearList().find(y =>  y.current === true)

              let payload = { "course_id": unit.id,
                      "calendar_schoolyear": calendarYear.value,
                      "term": term.key,
                    }

              SchoolService.create("planning", payload)
                .then(response => {
                  payload = response.data
                  payload.course.year_id = this.yearId
                  vm.planning.push(payload)
                })
                .catch(err => {
                  console.log(err)
                });
              }
        }
    },
    failure (error) {
       this.$refs.saveNoticeTop.failed() 
      this.$refs.saveNoticeBottom.failed() 
       console.log('Pupil update error ' + error);
    },
    completion(val) {
      if (val == true) {
        this.$refs.saveNoticeTop.saved();
        this.$refs.saveNoticeBottom.saved();
        window.scrollTo(0, 0);
      } else {
        this.$refs.saveNoticeTop.failed();
        this.$refs.saveNoticeBottom.failed();
      }
    },
    closeWindow () {
      this.$router.push({ path: '/' });
      window.scrollTo(0, 0);
    },
  },
  components: {
    savenotice,
    innertopbar
  }
}
</script>

<style scoped>



</style>